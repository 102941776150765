import { lazy, Suspense } from "react";

const routes = [
  {
    path: "/",
    children: [
      {
        path: "/",
        element: () => import("../pages/home")
      },
    ]
  },
  {
    path: "/about",
    children: [
      {
        path: "/about",
        element: () => import("../pages/about")
      }
    ]
  },
  {
    path: "/study",
    children: [
      {
        path: "/study/english",
        element: () => import("../pages/english")
      }
    ]
  },
  {
    path: "*",
    element: () => import("../pages/404")
  }
]

function LazyElement(props: any) {
  const { importFunc } = props;
  const LazyComponent = lazy(importFunc);
  return (
    <Suspense fallback={null}>
      <LazyComponent />
    </Suspense>
  );
}

function dealRoutes(routesArr: any) {
  if (routesArr && Array.isArray(routesArr) && routesArr.length > 0) {
    routesArr.forEach((route) => {
      if (route.element && typeof route.element == "function") {
        const importFunc = route.element;
        route.element = <LazyElement importFunc={importFunc} />;
      }
      if (route.children) {
        dealRoutes(route.children);
      }
    });
  }
}
dealRoutes(routes);

export default routes;