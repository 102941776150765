import { BrowserRouter, useRoutes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ChakraProvider } from '@chakra-ui/react'
import theme from "./chakraTheme";
import routes from "./routes";

import "./App.css";

function RouteElement() {
  const element = useRoutes(routes as any);
  return element;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0,
    },
  },
});

function APP() {
  return (
    <>
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <RouteElement />
        </BrowserRouter>
      </QueryClientProvider>
    </ChakraProvider>
    </>
  );
}

export default APP;
