import { tagAnatomy } from "@chakra-ui/anatomy";
import {
  ComponentStyleConfig,
  createMultiStyleConfigHelpers,
  defineStyleConfig,
  extendTheme,
} from "@chakra-ui/react";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(tagAnatomy.keys);

const Tag = defineMultiStyleConfig({
  sizes: {
    
  },
  variants: {
    
  },
});

const Button = defineStyleConfig({
  baseStyle: {},
  sizes: {

  },

  variants: {
    
  },
  defaultProps: {
  },
});


const Input: ComponentStyleConfig = {
  variants: {
    
  }
};

const theme = extendTheme({
  colors: {

  },
  fonts: {
    xiaolai: "xiaolai",
    // heheer: "heheer",
    hengshui: "hengshui",
  },
  components: {
    Button,
    Tag,
    Input,
  },
});

export default theme;
